/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import { Columns } from './PageStructure'
import { ColorPrimaryWhite } from '../styleVariables'
import Header from './header'
import Footer from './Footer'
import Disclaimer from './Disclaimer'
import './layout.css'

interface LayoutProps {
  children: React.ReactNode
  hideSignUpButton?: boolean
  hideFooter?: boolean
  useH2?: boolean
  showDisclaimer?: boolean
}

const Layout = (props: LayoutProps) => {
  const { children, hideSignUpButton, hideFooter, useH2 = false, showDisclaimer = false } = props
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <PageContainer id={'pageContainer'}>
      <Header hideSignUpButton={hideSignUpButton} siteTitle={data.site.siteMetadata?.title || `Title`} useH2={useH2} />
      <Content>
        {children}
      </Content>
      {!hideFooter && <Footer />}
      {showDisclaimer && <Disclaimer />}
    </PageContainer>
  )
}

const PageContainer = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0px;
  left: 0px;
`

const Content = styled.main`
  grid-template-rows: auto;
  ${Columns}
  background-color: ${ColorPrimaryWhite};
`

export default Layout
