import styled, { css } from 'styled-components'

export const Device = {
  mobile: '999px',
  small: '700px',
  tiny: '500px',
  micro: '400px',
  nano: '319px',
}

export const Columns = css`
  display: grid;
  grid-template-columns: 5.5rem [contentStart] repeat(12, [col-start] minmax(2rem, 12rem) [col-end]) [contentEnd] 5.5rem;
  grid-column-gap: 2rem;
  @media (max-width: ${Device.mobile}) {
    grid-template-columns: 1rem [contentStart] repeat(12, [col-start] 1fr [col-end]) [contentEnd] 1rem;
    grid-column-gap: 1rem;
  }
`

export const QuestionnaireColumns = css`
  display: grid;
  grid-template-columns: 1fr [contentStart] repeat(12, [col-start] minmax(0.1rem, 3.125rem) [col-end]) [contentEnd] 1fr;
  grid-column-gap: 2rem;
  @media (max-width: ${Device.mobile}) {
    grid-template-columns: 1fr [contentStart] repeat(12, [col-start] 1fr [col-end]) [contentEnd] 1fr;
    grid-column-gap: 1rem;
  }
`
