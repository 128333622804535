import { css } from 'styled-components'

export const ColorPrimaryWhite = 'rgba(255,255,255,1)'
export const ColorSupernova = 'rgba(255, 198, 0, 1.00)'
export const ColorPrimaryDarkGreen = 'rgba(73,122,43,1)'
export const ColorPrimaryDarkBlue = 'rgba(2,47,88,1)'
export const ColorButtonPlum = 'rgba(94,32,104,1)'
export const ColorButtonLightPlum = 'rgba(145,79,156,1)'
export const ColorButtonBlue = 'rgba(3,114,131,1)'
export const ColorFlourishYellow = 'rgba(242, 211, 110, 1)'
export const ColorFlourishDarkYellow = 'rgba(171,131,0,1)'
export const ColorFlourishGreenBright = 'rgba(99,158,16, 1)'
export const ColorTextLightGray = 'rgba(107,108,111,1)'
export const ColorTextDarkGray = 'rgba(65,64,66,1)'
export const ColorOtherLightGray = 'rgba(203,204,206,1)'
export const ColorOtherPaleGray = 'rgba(248,248,248,1)'
export const ColorError = 'rgba(170,0,8,1)'
export const ColorOtherGrey = 'rgba(187,187,187,1)'
export const ColorTruepillBlue = 'rgba(68, 189, 208, 1)'
export const ColorFountainBlue = 'rgba(83, 179, 187, 1)'
export const ColorPowderBlue = 'rgba(178, 224, 232, 1.00)'
export const ColorHumanaGrey = '#53575A'
export const ColorErrorRed = 'rgba(170,0,8,1)'
export const ColorBlueLagoon = 'rgba(2, 113, 131, 1.00)'

export const FontDesktopH1 = css`
  font-size: 50px;
  font-family: 'HumanaRegular';
  font-weight: normal;
  line-height: 62px;
`

export const FontDesktopH2 = css`
  font-size: 36px;
  font-family: 'HumanaRegular';
  font-weight: normal;
  line-height: 48px;
`
export const FontDesktopH3 = css`
  font-size: 26px;
  font-family: 'HumanaRegular';
  font-weight: bold;
  line-height: 32px;
`

export const FontDesktopH4 = css`
  font-size: 22px;
  font-family: 'HumanaRegular';
  font-weight: normal;
  line-height: 32px;
`

export const FontDesktopH5 = css`
  font-size: 20px;
  font-family: 'HumanaRegular';
  font-weight: 700;
  line-height: 26px;
`

export const FontDesktopPBody = css`
  font-size: 18px;
  font-family: 'HumanaRegular';
  font-weight: normal;
  line-height: 27px;
`
export const FontDesktopPBodyBold = css`
  font-size: 18px;
  font-family: 'HumanaRegular';
  font-weight: bold;
  line-height: 24px;
`

export const FontDesktopPMedium = css`
  font-size: 16px;
  font-family: 'HumanaRegular';
  font-weight: normal;
  line-height: 24px;
`

export const FontDesktopPSmall = css`
  font-size: 14px;
  font-family: 'HumanaRegular';
  font-weight: normal;
  line-height: 24px;
`
export const FontMobileH1 = css`
  font-size: 34px;
  font-family: 'HumanaRegular';
  font-weight: normal;
  line-height: 44px;
`
export const FontMobileH2 = css`
  font-size: 30px;
  font-family: 'HumanaRegular';
  font-weight: normal;
  line-height: 32px;
`
export const FontMobileH3 = css`
  font-size: 22px;
  font-family: 'HumanaRegular';
  font-weight: bold;
  line-height: 28px;
`
export const FontMobileH4 = css`
  font-size: 20px;
  font-family: 'HumanaRegular';
  font-weight: normal;
  line-height: 26px;
`
export const FontMobilePBody = css`
  font-size: 16px;
  font-family: 'HumanaRegular';
  font-weight: normal;
  line-height: 24px;
`
export const FontMobilePBodyBold = css`
  font-size: 16px;
  font-family: 'HumanaRegular';
  font-weight: bold;
  line-height: 24px;
`

export const FontQuestionnaireTitle = css`
  font-family: 'HumanaRegular';
  font-style: normal;
  font-weight: normal;
  font-size: 34px;
  line-height: 44px;
`

export const FontQuestionnaireBodyText = css`
  font-family: 'HumanaRegular';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
`

export const FontQuestionnaireBodyTextBold = css`
  font-family: 'HumanaRegular';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
`

export const FontCheckoutDesktopH1 = css`
  font-family: 'HumanaRegular';
  font-size: 48px;
  line-height: 62px;
`

export const FontCheckoutDesktopH2 = css`
  font-family: 'HumanaRegular';
  font-size: 38px;
  line-height: 50px;
`
export const FontCheckoutDesktopH3 = css`
  font-family: 'HumanaRegular';
  font-size: 28px;
  line-height: 36px;
`

export const FontCheckoutDesktopH3SemiBold = css`
  font-family: 'HumanaRegular';
  font-size: 28px;
  line-height: 36px;
`

export const FontCheckoutSubtitle = css`
  font-family: 'HumanaRegular';
  font-size: 20px;
  line-height: 26px;
`

export const SemiBold = css`
  font-weight: 700;
`

export const Regular = css`
  font-weight: 400;
`

export const FontCheckoutDesktopBody = css`
  font-family: 'HumanaRegular';
  font-size: 18px;
  line-height: 24px;
`

export const FontCheckoutDesktopBodySmall = css`
  font-family: 'HumanaRegular';
  font-size: 14px;
  line-height: 16px;
`
