import React from 'react'
import styled from 'styled-components'
import { ArrowButton } from './Button'
import { navigate } from 'gatsby'

const SignUpButton = (props: { className: string }) => {
  return (
    <ArrowButton
      className={props.className}
      onClick={() => {
        navigate('/pre-questionnaire')
      }}
    >
      Sign Up
    </ArrowButton>
  )
}

export default SignUpButton
