import React from 'react'
import styled from 'styled-components'
import {
  ColorPrimaryWhite,
  ColorTextLightGray,
} from '../../styleVariables'

const Spinner = styled.div`
  border: 8px solid ${ColorPrimaryWhite};
  border-top: 8px solid ${ColorTextLightGray};
  border-radius: 50%;
  width: 10px;
  height: 10px;
  animation: spin 2s linear infinite;
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`

export default Spinner
