import React from 'react'
import styled, { css } from 'styled-components'
import {
  FontDesktopPBodyBold,
  ColorButtonPlum,
  ColorPrimaryWhite,
  ColorFlourishYellow,
  ColorTextDarkGray,
  ColorPrimaryDarkBlue,
} from '../styleVariables'
import Arrow from '../assets/icons/arrow.svg'
import BackArrow from '../assets/icons/back-arrow.svg'
import Spinner from './Spinner'

export interface ButtonProps {
  className?: string
  children: React.ReactNode | string
  onClick?: () => void
  disabled?: boolean
  wide?: boolean
}

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const { onClick, children, disabled, wide } = props
  return (
    <StyledButton
      ref={ref}
      wide={wide}
      disabled={disabled}
      className={props.className}
      onClick={() => !disabled && onClick && onClick()}
    >
      {children}
    </StyledButton>
  )
})

export const ArrowButton = (props: ButtonProps) => {
  const { children, ...rest } = props
  return (
    <Button {...rest}>
      {children}
      <Arrow />
    </Button>
  )
}

export const BackButton = (props: ButtonProps) => {
  const { onClick, children, disabled } = props
  return (
    <StyledBackButton disabled={disabled} className={props.className} onClick={() => !disabled && onClick && onClick()}>
      {children}
    </StyledBackButton>
  )
}

export const BackArrowButton = (props: ButtonProps) => {
  const { children, ...rest } = props
  return (
    <BackButton {...rest}>
      <BackArrow />
      {children}
    </BackButton>
  )
}

export const UpdateAndSave = (props: {
  disabled?: boolean
  buttonText?: string
  onClickCallback(): void
  processing?: boolean
  className?: string
}) => {
  const { disabled, onClickCallback, buttonText = 'Update and Save', processing, className } = props
  const handleClick = evt => {
    if (!disabled && onClickCallback) {
      onClickCallback()
    }
  }

  return (
    <StyledUpdateSave className={className} onClick={handleClick} disabled={disabled}>
      {processing ? <Spinner className="spinner" id="spinner"></Spinner> : buttonText}
    </StyledUpdateSave>
  )
}

const PointerCursor = css`
  cursor: pointer;
`

const Disabled = css`
  cursor: default;
  filter: grayscale(95%);
  color: ${ColorPrimaryWhite};
  > svg {
    fill: ${ColorPrimaryWhite};
  }
`

const StyledButton = styled.button`
  ${PointerCursor}
  border-radius: 0.25rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  ${FontDesktopPBodyBold};
  background-color: ${ColorButtonPlum};
  user-select: none;
  color: ${ColorPrimaryWhite};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  border: none;
  width: 12.8125rem;
  > svg {
    margin-left: 0.875rem;
    fill: ${ColorPrimaryWhite};
  }
  ${({ wide }) => wide && `width: 15rem;`}
  ${({ disabled }) => disabled && Disabled}
`

const StyledBackButton = styled.button`
  ${PointerCursor}
  border-radius: 0.25rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-right: 1rem;
  ${FontDesktopPBodyBold};
  background-color: ${ColorFlourishYellow};
  user-select: none;
  color: ${ColorTextDarkGray};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  border: none;
  width: 6.8125rem;
  > svg {
    margin-left: 0.875rem;
    fill: ${ColorTextDarkGray};
    transform: rotate(180deg);
  }
  ${({ disabled }) => disabled && Disabled}
`

const StyledUpdateSave = styled(Button)`
  background-color: ${ColorFlourishYellow};
  color: ${ColorPrimaryDarkBlue};
  margin-top: 3rem;
  margin-left: auto;
`

export default Button
