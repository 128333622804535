import React from 'react'
import styled from 'styled-components'
import { Columns, Device } from '../components/PageStructure'
import {
  ColorOtherPaleGray,
  ColorTextLightGray,
  ColorOtherLightGray,
} from '../styleVariables'
import Ellipse from '../assets/icons/ellipse.svg'

const Disclaimer = () => {

  return (
    <Container>
      <Divider />
      <CircleSection>
        <StyledCircle>
          <Ellipse />
        </StyledCircle>
        <DescOffset>
          TruePill is a third party vendor. Humana’s contract with TruePill does not eliminate a member of any obligations under the policy or change the terms of the policy. Participation in the TruePill program is voluntary. All representations and warranties contained in this marketing material are made solely by TruePill, not Humana. Links to various other websites from this site are provided for your convenience only and do not constitute or imply endorsement by Humana, Inc. or its subsidiaries of these sites, any products, views, or services described on these sites, or of any other material contained therein. Humana disclaims responsibility for their content and accuracy.
        </DescOffset>
      </CircleSection>
      <SquareSection>
        <StyledSquare />
        <DescOffset>
          Limitations on telehealth services, also referred to as virtual visits or telemedicine, vary by state. These services are not a substitute for emergency care and are not intended to replace your primary care provider or other providers in your network. Any descriptions of when to use telehealth services are for informational purposes only and should not be construed as medical advice. Please refer to your evidence of coverage for additional details on what your plan may cover or other rules that may apply.
        </DescOffset>
      </SquareSection>
    </Container>
  )
}

const Divider = styled.hr`
  border: none;
  border-bottom: 1px solid ${ColorOtherLightGray};
  grid-row: 1;
  grid-column: contentStart/contentEnd;
  width: 100%;
`

const CircleSection = styled.div`
  margin-top: 20px;
  position: relative;
  grid-row: 2;
  grid-column: contentStart/contentEnd;
  color: ${ColorTextLightGray};
`
const DescOffset = styled.p`
  margin-left: 16px;
  margin-top: 0;
  margin-bottom: 0;
`

const StyledCircle = styled.sup`
  position: absolute;
  > svg {
    width: 8px;
    height: 8px;
    circle {
      stroke: ${ColorTextLightGray};
      stroke-width: 16;
    }
  }
`

const StyledSquare = styled.sup`
  position: absolute;
  top: 5px;
  border: 1px solid ${ColorTextLightGray};
  width: 8px;
  height: 8px;
  box-sizing: border-box;
  transform: rotate(-45deg);
`

const SquareSection = styled.div`
  margin-top: 20px;
  position: relative;
  grid-row:3;
  grid-column: contentStart/contentEnd;
  color: ${ColorTextLightGray};
`

const Container = styled.footer`
  display: grid;
  background-color: ${ColorOtherPaleGray};
  ${Columns}
  grid-template-rows: auto auto auto 3rem;
`

export default Disclaimer
