import { Link } from 'gatsby'
import React, {useMemo} from 'react'
import styled, { css } from 'styled-components'
import {
  ColorPrimaryWhite,
  ColorOtherGrey,
  ColorFlourishGreenBright,
  ColorFlourishYellow,
  ColorPrimaryDarkBlue,
} from '../styleVariables'
import HumanaLogo from '../assets/icons/humana-logo.svg'
import { Columns, Device } from './PageStructure'
import SignUpButton from './SignUpButton'

const Header = (props: { siteTitle?: string; hideSignUpButton?: boolean, useH2: boolean }) => {
  const {useH2} = props
  return (
    <StyledHeader>
      <TitleBox>
        {!useH2 && (
          <TitleH1>
            <Link to="/" aria-label='Humana - link to homepage'>
              <HumanaLogo />
            </Link>
            <TagLine>Men's Health</TagLine>
          </TitleH1>
        )}
        {useH2 && (
          <TitleH2>
            <Link to="/" aria-label='Humana - link to homepage'>
              <HumanaLogo />
            </Link>
            <TagLine>Men's Health</TagLine>
          </TitleH2>
        )}
      </TitleBox>
      {!props.hideSignUpButton && <StyledSignUpButton />}
    </StyledHeader>
  )
}

//const HumanaLogo = () => <humanaLogo />

const StyledHeader = styled.header`
  ${Columns}
  position: sticky;
  height: 4.6rem;
  top: 0px;
  align-items: center;
  background-color: ${ColorPrimaryWhite};
  border-bottom: 1px solid ${ColorOtherGrey};
  border-top: 0.25rem solid ${ColorFlourishGreenBright};
  z-index: 10;
`

const TitleBox = styled.div`
  grid-column: contentStart / contentEnd;
  margin: 0rem;
  grid-row: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  @media (max-width: ${Device.mobile}) {
    grid-column: contentStart / span 4;
  }
`

const TitleStyle = css`
  font-size: 2rem;
  min-width: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 0rem;
  margin-bottom: 0rem;
  @media (max-width: ${Device.mobile}) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    > p {
      margin-left: 0rem;
      text-align: center;
    }
  }
`

const TitleH1 = styled.h1`
  ${TitleStyle}
`

const TitleH2 = styled.h2`
  ${TitleStyle}
`

const TagLine = styled.p`
  font-size: 1rem;
  font-weight: 400;
  font-family: 'HumanaRegular';
  margin-left: 1.875rem;
  margin-top: 0rem;
  margin-bottom: 0rem;
  white-space: nowrap;
`

const StyledSignUpButton = styled(SignUpButton)`
  min-width: 0;
  grid-column: 9 / contentEnd;
  justify-self: end;
  grid-row: 1;
  background-color: ${ColorFlourishYellow};
  color: ${ColorPrimaryDarkBlue};
  > svg {
    fill: ${ColorPrimaryDarkBlue};
  }
  @media (max-width: ${Device.mobile}) {
    max-width: 7.5rem;
    padding-left: 1rem;
    > svg {
      margin-left: 0.5rem;
      fill: ${ColorPrimaryDarkBlue};
    }
  }
`

export default Header
