import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { Columns, Device } from '../components/PageStructure'
import {
  ColorOtherPaleGray,
  ColorTextDarkGray,
  ColorTextLightGray,
  ColorOtherLightGray,
  ColorTruepillBlue,
  FontDesktopPSmall,
} from '../styleVariables'
import TruepillLogo from '../assets/icons/truepill-logo.svg'

const year = new Date().getFullYear()

const Footer = () => (
  <NewFooter>
    <TruepillContainer>
      <TruepillLogo />
      <p>© Truepill {year} All Rights Reserved.</p>
    </TruepillContainer>
    <Divider />
    <ListContainer>
      <ul>
        <li>
          <strong>Contact us</strong>
        </li>
        <li>Phone: (855) 645-8114</li>
        <li>Hours: 8AM-6PM CT</li>
        <li>humana@truepill.com</li>
      </ul>
      <ul>
        <li>
          <strong>Legal</strong>
        </li>
        <li>
          <Link to={'/terms'}>Terms of Service</Link>
        </li>
        <li>
          <Link to={'/privacy-policy'}>Privacy Policy</Link>
        </li>
        <li>
          <Link to={'/informed-consent'}>Informed Consent</Link>
        </li>
        <li>
          <Link to={'/privacyNotes'}>HIPAA Privacy Notice</Link>
        </li>
      </ul>
    </ListContainer>
  </NewFooter>
)

const NewFooter = styled.footer`
  ${Columns}
  padding: 20px 0;
  display: grid;
  justify-content: center;
  width: 100%;
  background-color: ${ColorOtherPaleGray};
  border: none;
  border-top: 0.125rem solid ${ColorOtherLightGray};

  @media (min-width: 769px) {
    > div {
      flex-wrap: nowrap;
      justify-content: space-evenly;
    }
  }
  @media (max-width: 700px) {
    display: flex;
    flex-direction: column;
  }
`

const TruepillContainer = styled.div`
  width: 100%;
  align-self: center;
  text-align: center;
  grid-column: contentStart / 5;

  > svg {
    fill: rgb(68, 189, 208);
  }

  p {
    font-size: 0.9rem;
  }

  @media (min-width: 769px) {
    max-width: 350px;
    text-align: left;
  }
`
const Divider = styled.div`
  grid-row: 1;
  grid-column: 5;
  align-self: left;
  height: 10rem;
  border-right: 1px solid ${ColorOtherLightGray};
  width: 0rem;
  @media (max-width: ${Device.small}) {
    display: none;
  }
`

const ListContainer = styled.div`
  width: 100%;
  align-self: center;
  display: flex;
  flex-wrap: wrap;
  grid-column: 6 / contentEnd;

  ul {
    width: 100%;
    text-align: left;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li,
  a {
    font-size: 0.9rem;
    line-height: 1.8;
    color: ${ColorTextLightGray};
    text-decoration: none;
  }

  li:first-child {
    color: rgb(65, 64, 66);
  }

  @media (min-width: 769px) {
    flex-wrap: nowrap;
    justify-content: space-evenly;
  }

  @media (max-width: 700px) {
    padding-left: 2rem;
    padding-right: 2rem;
    width: auto;
    ul {
      margin-top: 1rem;
    }
  }
`

export default Footer
